<script lang="ts" setup>
import { useRoute } from 'vue-router';

import type { MenuItem } from './menu-item';
import SideMenuItem from './SideMenuItem.vue';

const props = defineProps<{
  menu: MenuItem[];
}>();

const route = useRoute();
const { isGuest, guestUserName } = useAuthStore();

const items = computed<MenuItem[]>(() => {
  return props.menu.filter((item) => item.visible !== false);
});

const accordionUi = {
  item: {
    padding: '',
    size: 'text-base',
    color: 'text-inherit'
  }
};

const isActive = (item: MenuItem): boolean => {
  return item.name === route.name;
};
</script>

<template>
  <div class="h-full flex flex-col bg-card border-r border-card shadow-sm">
    <div
      v-if="isGuest"
      class="p-3"
    >
      <UButton
        class="text-inherit"
        variant="ghost"
        size="lg"
        data-test="guest-buttom"
        :label="`Logged as Guest: ${guestUserName}`"
      />
      <UDivider class="bg-company-1" />
    </div>
    <UAccordion
      class="p-3"
      :items="items"
      :ui="accordionUi"
    >
      <template #default="{ item, open }">
        <UButton
          variant="ghost"
          class="text-left text-base text-inherit hover:bg-company-1/50"
          :class="{ 'bg-company-1': isActive(item) }"
          :ui="{ padding: { sm: 'p-3' }, gap: { sm: 'gap-x-3' } }"
          :to="item.children ? undefined : item"
          data-test="menu-item"
        >
          <template #leading>
            <UIcon
              class="min-w-4 min-h-5 text-2xl"
              :name="item.icon"
              data-test="icon"
              dynamic
            />
          </template>

          <span
            class="flex-1"
            data-test="label"
          >
            {{ item.label }}
          </span>

          <template
            v-if="item.children"
            #trailing
          >
            <UIcon
              class="min-w-4 min-h-5 text-2xl subtext-card transform transition-transform duration-200"
              name="ic:round-keyboard-arrow-down"
              :class="[open && 'rotate-180']"
              data-test="expanded-icon"
            />
          </template>
        </UButton>
      </template>

      <template #item="{ item }">
        <ul
          v-if="item.children"
          class="flex-1"
          data-test="children"
        >
          <template
            v-for="(child, idx) in item.children"
            :key="idx"
          >
            <SideMenuItem
              v-if="child.visible !== false"
              :item="child"
              data-test="child"
            />
          </template>
        </ul>
      </template>
    </UAccordion>
  </div>
</template>
