<script lang="ts" setup>
interface Option {
  label: 'On' | 'Off';
  value: ConsentStatus;
}

const props = defineProps<{
  modelValue: Cookies;
  companyName: string
}>();

const emits = defineEmits<{
  (e: 'confirm'): void;
  (e: 'accept-all'): void;
  (e: 'update:modelValue', v: Cookies): void;
}>();

const options: Option[] = [
  { label: 'On', value: 'granted' },
  { label: 'Off', value: 'denied' }
];

const onConfirm = (): void => {
  emits('confirm');
};

const onAcceptAll = (): void => {
  emits('accept-all');
};

const onUpdateModelValue = (v: ConsentStatus): void => {
  emits('update:modelValue', { ...props.modelValue, analyticsStorage: v });
};
</script>

<template>
  <UCard>
    <template #header>
      <h1 class="text-2xl font-semibold">Cookies Settings</h1>
    </template>

    <div data-test="text">
      {{ props.companyName }} uses cookies to analyze site traffic and usage,
      helping us improve your experience. Below are the details:
    </div>

    <hr class="mb-4 mt-4">

    <div class="flex justify-between mb-2">
      <span class="text-xl font-semibold">Performance Cookie</span>

      <USelect
        :model-value="props.modelValue.analyticsStorage"
        data-test="performance-cookie"
        :options="options"
        select-class="text-card"
        @update:model-value="onUpdateModelValue"
      />
    </div>

    <div class="flex flex-col gap-2">
      <span>
        These cookies are used to understand how you interact with our
        website, including:
      </span>

      <ul class="list-disc pl-5">
        <li>Analyzing site traffic and usage</li>
        <li>Understanding how you arrived at our site</li>
        <li>Gathering data through tools like Google Analytics</li>
      </ul>

      <span> Read more about our
        <NuxtLink
          class="font-semibold text-company-1 cursor-pointer"
          :to="{ name: 'cookiespolicy' }"
          target="_blank"
        >
          Cookies Policy
        </NuxtLink>
        .
      </span>
    </div>

    <template #footer>
      <div class="flex justify-end gap-4">
        <UButton
          variant="solid"
          class="bg-company-1 w-40 py-4 font-semibold text-md justify-center"
          data-test="accept-all-button"
          @click="onAcceptAll"
        >
          Accept All
        </UButton>

        <UButton
          variant="solid"
          class="bg-company-1 w-40 py-4 font-semibold text-md justify-center"
          data-test="confirm-button"
          @click="onConfirm"
        >
          Done
        </UButton>
      </div>
    </template>
  </UCard>
</template>
