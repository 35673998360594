<script lang="ts" setup>
import { useRoute } from 'vue-router';

const route = useRoute();

const analytics = analyticsUtils();
const cookieStore = useCookieStore();
const preferenceStore = usePreferenceStore();

const shouldOpenCookiesModal = computed<boolean>(() => {
  const isCookiesPolicyRoute: boolean = route.path === '/cookiespolicy';
  const isCookieConsentStored: boolean = cookieStore.isCookieConsentStored();

  return !isCookiesPolicyRoute && !isCookieConsentStored;
});

const setConsentBasedOnCookies = (cookies: Cookies): void => {
  analytics.setConsent({ analytics_storage: cookies.analyticsStorage });
};

const reloadConsentPreferences = (): void => {
  if (cookieStore.isCookieConsentStored()) {
    setConsentBasedOnCookies(cookieStore.getCookies());
  }
};

onMounted(reloadConsentPreferences);
</script>

<template>
  <CookiesModal
    v-if="shouldOpenCookiesModal"
    :company-name="preferenceStore.preference.company.name"
    :logo-url="preferenceStore.preference.template.siteLogoUrl"
    @update:cookies="setConsentBasedOnCookies"
  />
</template>
