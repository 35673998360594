<script lang="ts" setup>
import type { MenuItem } from './menu-item';

import SideMenu from '~/components/layouts/default/SideMenu.vue';

const props = defineProps<{
  modelValue: boolean;
  menu: MenuItem[];
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const onUpdateModelValue = (value: boolean): void => {
  emits('update:modelValue', value);
};
</script>

<template>
  <USlideover
    :model-value="props.modelValue"
    side="left"
    data-test="drawer"
    class="w-4/5"
    @update:model-value="onUpdateModelValue"
  >
    <SideMenu :menu="props.menu" />
  </USlideover>
</template>
