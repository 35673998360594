import { deepClone } from '#imports';

interface Store {
  state: Ref<State>;
  isAnyCookieConsentGranted: ComputedRef<boolean>;
  getCookies: () => State;
  setCookies: (cookies: State) => void;
  isCookieConsentStored: () => boolean;
}

interface State {
  analyticsStorage: ConsentStatus;
}

const STORAGE_NAME: string = 'customer-cookies';

const useCookieStore = defineStore(
  STORAGE_NAME,
  (): Store => {
    const state = ref<State>({ analyticsStorage: 'granted' });

    const isAnyCookieConsentGranted = computed<boolean>(() => {
      return Object.values(state.value).some((value) => value === 'granted');
    });

    const setCookies = (cookies: State): void => {
      state.value = deepClone<State>(cookies);
    };

    const getCookies = (): State => {
      return deepClone<State>(state.value);
    };

    const isCookieConsentStored = (): boolean => {
      return localStorage.getItem(STORAGE_NAME) !== null;
    };

    return {
      state,
      getCookies,
      setCookies,
      isCookieConsentStored,
      isAnyCookieConsentGranted
    };
  },
  {
    persist: {
      storage: persistedState.localStorage
    }
  }
);

export { useCookieStore };
export type { State as Cookies };
