<script lang="ts" setup>
import type { MenuItem } from './menu-item';

const props = defineProps<{
  item: MenuItem
}>();

const route = useRoute();

const isActive = computed<boolean>(() => {
  return props.item.name === route.name;
});
</script>

<template>
  <li>
    <NuxtLink
      class="relative hover:bg-company-1/50 flex items-center py-3 pl-6 pr-3 my-1 font-medium rounded-md cursor-pointer transition-colors"
      :to="props.item"
      :class="{
        'bg-company-1': isActive
      }"
    >
      <UIcon
        :name="props.item.icon"
        class="text-2xl mr-3"
        data-test="icon"
        dynamic
      />

      <span
        class="text-nowrap"
        data-test="label"
      >
        {{ props.item.label }}
      </span>
    </NuxtLink>
  </li>
</template>

<style lang="scss" scoped>
:deep() {
  .side-menu-item {
    &__button {
      gap: 2rem;
      padding: 1rem;
    }
  }
}
</style>
