<script lang="ts" setup>
import CookiesConsent from './CookiesConsent.vue';
import CookiesOptions from './CookiesOptions.vue';

const props = defineProps<{
  logoUrl?: string;
  companyName: string;
}>();

const emits = defineEmits<{
  (e: 'update:cookies', v: Cookies): void;
}>();

const cookieStore = useCookieStore();

const isOpen = ref<boolean>(true);
const isManaging = ref<boolean>(false);

const cookies = ref<Cookies>(cookieStore.getCookies());

const onManage = (): void => {
  isManaging.value = true;
};

const close = (): void => {
  isOpen.value = false;
};

const onAccept = (): void => {
  onConfirm();
};

const onAcceptAll = (): void => {
  cookies.value.analyticsStorage = 'granted';
  onConfirm();
};

const onConfirm = (): void => {
  cookieStore.setCookies(cookies.value);
  emits('update:cookies', cookies.value);

  close();
};
</script>

<template>
  <UModal
    :model-value="isOpen"
    :ui="{ container: 'items-center' }"
    prevent-close
  >
    <CookiesOptions
      v-if="isManaging"
      v-model="cookies"
      :company-name="props.companyName"
      @confirm="onConfirm"
      @accept-all="onAcceptAll"
    />

    <CookiesConsent
      v-else
      :logo-url="props.logoUrl"
      :company-name="props.companyName"
      @manage="onManage"
      @accept="onAccept"
    />
  </UModal>
</template>
